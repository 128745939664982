import { useState } from "react"

import { ReactComponent as IconChevronDown } from "../../../../assets/icons/icon-chevron-down.svg"
import i18n from "../../../../i18n"

const businessOptions = i18n
	.t("monoStepFunnel.formPanel.fields.business.options", { returnObjects: true })
	?.map((businessOption, index) => {
		return { value: index + 1, text: businessOption }
	})
const companySizeOtions = [
	{ value: "1-25", text: "1 - 25" },
	{ value: "25-100", text: "25 - 100" },
	{ value: "100-500", text: "100 - 500" },
	{ value: "500-1000", text: "500 - 1000" },
	{ value: "1000+", text: "1000+" }
]

export default function Select({ id, name = id, placeholder, optionsType = "business" }) {
	const [selectedOption, setSelectedOption] = useState(null)
	const [optionsListIsVisible, setOptionsListIsVisible] = useState(false)
	const options =
		optionsType === "business" ? businessOptions : optionsType === "companySize" ? companySizeOtions : []

	function handleOptionSelection(option) {
		setSelectedOption(option)
		setOptionsListIsVisible(false)
	}

	function toggleOptionsListVisibility() {
		setOptionsListIsVisible(currentValue => !currentValue)
	}

	return (
		<div className="select">
			<button
				type="button"
				className="toggleListButton"
				title={selectedOption ? selectedOption?.value : null}
				onClick={toggleOptionsListVisibility}
			/>
			<input type="hidden" name={name} id={id} value={selectedOption ? selectedOption?.value : ""} />
			<div className={`fieldInput selectValue${!selectedOption ? " hasPlaceholder" : ""}`}>
				<span className="selectValueText">{!selectedOption ? placeholder : selectedOption.text}</span>
				<IconChevronDown className="icon" />
			</div>
			<div className={`selectOptionsListContainer${optionsListIsVisible ? " isVisible" : ""}`}>
				<ol className="selectOptionsList">
					{options.map((option, index) => {
						return (
							<li className="selectOptionItem" key={index}>
								<button
									type="button"
									className={`button${
										selectedOption && selectedOption?.value === option.value ? " isSelected" : ""
									}`}
									onClick={() => handleOptionSelection(option)}
								>
									{option.text}
								</button>
							</li>
						)
					})}
				</ol>
			</div>
		</div>
	)
}
